import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取巡检模版库
 */
export function getTemplateLibrary() {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/library/list',
        method: 'get',
    })
}

/**
 * 获取巡检模版
 */
export function getTemplateList(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/list',
        method: 'post',
        data: params
    })
}

/**
 * 获取巡检模版详细信息
 */
export function getTemplateInfo(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/detail?templateId=' + params.templateId,
        method: 'get',
    })
}

/**
 * 新建模版
 */
export function createTemplate(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/add',
        method: 'post',
        data: params
    })
}

/**
 * 复制模版
 */
export function copyTemplate(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/copy',
        method: 'post',
        params
    })
}

/**
 * 更新模版
 */
export function updateTemplate(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/update',
        method: 'put',
        data: params
    })
}

/**
 * 删除模版
 */
export function deleteTemplate(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/del?templateId='+params.templateId,
        method: 'delete',
    })
}

/**
 * 新建模版库
 */
export function createTemplateLibrary(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/library/add',
        method: 'post',
        data: params
    })
}

/**
 * 更新模版库
 */
export function updateTemplateLibrary(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/library/update',
        method: 'put',
        data: params
    })
}

/**
 * 删除模版库
 */
export function deleteTemplateLibrary(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/template/library/del?templateLibraryId='+params.templateLibraryId,
        method: 'delete',
    })
}

/**
 * 查询单位编码
 * @param params
 */
export function findUnitCode(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}
