<template>
	<div class="app-container">
		<el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
			<!--巡检模版-->
			<el-tab-pane :label="$t('inspection.template.manager')" :name="tempTab.tabName" :key="tempTab.tabName">
				<el-row>
					<el-col :md="9">
						<el-input :placeholder="$t('inspection.template.pleaseInputName')" maxlength="80" v-model="inspectionTemplate.listQuery.templateName">
							<el-select v-model="inspectionTemplate.listQuery.templateLibraryId" clearable @change="getTemplate" class="selectWidth" slot="prepend" :placeholder="$t('inspection.template.pleaseSelectLib')">
								<el-option v-for="item in templateLibrary" :key="item.templateLibraryId" :label="item.templateLibraryName" :value="item.templateLibraryId">
								</el-option>
							</el-select>
							<el-button slot="append" @click="getTemplate" icon="el-icon-search"></el-button>
						</el-input>
					</el-col>
					<el-col :md="14" :offset="1">
						<el-button type="primary" plain @click="libraryDialogDisable = true">{{ $t("inspection.template.editLib") }}</el-button>
						<el-button type="primary" plain @click="createTemplate">{{ $t("inspection.template.add") }}
						</el-button>
					</el-col>
				</el-row>
				<el-row>
					<template v-for="item in inspectionTemplate.list">
						<el-col :md="5" :key="item.templateId" class="templateCard">
							<el-card class="box-card">
								<div slot="header" class="cardHeader">
									<span>{{ item.templateName || "&nbsp;" }}</span>
								</div>
								<ul class="templateInfoUl">
									<template v-if="item.remarks == '' || item.remarks == null">
										<li class="remark-style">
											{{ $t("inspection.template.notRemark") }}
										</li>
									</template>
									<template v-else>
										<li class="remark-style">{{ item.remarks }}</li>
									</template>
									<li v-if="item.lastUpdateDate != null">
										{{ item.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
									</li>
								</ul>
								<el-row>
									<el-tooltip :content="$t('commons.preview')" placement="bottom" :open-delay="openDelay">
										<el-button size="mini" @click="templatePreview(item.templateId)" class="el-icon-view"></el-button>
									</el-tooltip>
									<el-tooltip :content="$t('commons.copy')" placement="bottom" :open-delay="openDelay">
										<el-button size="mini" @click="copyTemplate(item.templateId)" class="el-icon-document-copy"></el-button>
									</el-tooltip>
									<el-tooltip :content="$t('commons.edit')" placement="bottom" :open-delay="openDelay">
										<el-button size="mini" @click="getTemplateDetail(item.templateId)" class="el-icon-edit"></el-button>
									</el-tooltip>
									<el-tooltip :content="$t('commons.delete')" placement="bottom" :open-delay="openDelay">
										<el-button size="mini" @click="deleteTemplate(item.templateId)" class="el-icon-delete"></el-button>
									</el-tooltip>
								</el-row>
							</el-card>
						</el-col>
					</template>
				</el-row>
			</el-tab-pane>
			<!--新增或编辑模版-->
			<el-tab-pane v-if="editTab" :label="editTitle" :name="editTab.tabName" :key="editTab.tabName" closable>
				<el-form role="templateForm" ref='addTemplate' :rules="inspectionTemplate.formRules" :model="inspectionTemplate.form" label-width="80px" label-position="left">
					<el-row>
						<el-form-item :label="$t('inspection.template.lib')">
							<el-input class="hidden" type="hidden" v-model="inspectionTemplate.form.templateId"></el-input>
							<el-select class="tempInput" v-model="inspectionTemplate.form.templateLibraryId" clearable :placeholder="$t('inspection.template.pleaseSelectLib')">
								<el-option v-for="item in templateLibrary" :key="item.templateLibraryId" :label="item.templateLibraryName" :value="item.templateLibraryId">
								</el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="templateName" :label="$t('inspection.template.templateName')">
							<el-input class="tempInput" maxlength="50" :show-word-limit="true" v-model="inspectionTemplate.form.templateName"></el-input>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item :label="$t('inspection.template.remark')">
							<el-input class="tempInput" maxlength="160" :show-word-limit="true" v-model="inspectionTemplate.form.remarks"></el-input>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item :label="$t('inspection.template.isNotes')">
							<el-switch v-model="inspectionTemplate.form.isNotes" active-value="Y" inactive-value="N"></el-switch>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item :label="$t('inspection.template.checkResult')">
							<el-input class="tempInput" :readonly="true" v-model="inspectionTemplate.form.inspectionResultItem"></el-input>
							<el-button @click="editResultItem" :style="{ marginLeft: '10px' }">{{ $t("inspection.template.editItem") }}</el-button>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item :label="$t('inspection.template.checkItem')">
							<vuedraggable class="wrapper" v-model="inspectionTemplate.form.inspectionCheckItemList" :animation='200'><transition-group>	
								<template v-for="(item, index) in inspectionTemplate.form.inspectionCheckItemList">
									<el-row :key="index" :style="{ marginBottom: '10px' }">
										<el-input class="tempInput marlt12" maxlength="50" :show-word-limit="true" v-model="item.checkItemName" :placeholder="$t('inspection.template.checkContent')"></el-input>
										<el-button @click="addCheckItem" type='text' v-if='index<1'>添加</el-button>
										<el-button @click="removeCheckItem(index)" type='text' >删除</el-button>
									</el-row>
								</template>
							</transition-group></vuedraggable>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="选择项">
							<vuedraggable class="wrapper" filter=".childs2" v-model="inspectionTemplate.form.inspectionSelectItemList" :animation='200' :move='checkOnMove'>
								<transition-group>	
									<div class='c_dragIts' v-for="(item, index) in inspectionTemplate.form.inspectionSelectItemList" :key="index">
										<div class="pt1 " >
											<el-row  >
												<el-input class="tempInput" v-model="item.selectItemName" maxlength="50" :show-word-limit="true" :placeholder="'标题'"></el-input>
												<el-radio-group v-model="item.selectType" :style="{ marginLeft: '10px' }">
													<el-radio-button :label="1">单选</el-radio-button>
													<el-radio-button :label="2">多选</el-radio-button>
												</el-radio-group>
												<el-select v-model="item.isMandatory" :style="{ marginLeft: '10px' }" class='marlt12' :placeholder="'是否必选'">
													<el-option value="N" :label="'非必选'"></el-option>
													<el-option value="Y" :label="'必选'"></el-option>
												</el-select>
												<el-button @click="addSelectItem" type='text'  >添加</el-button>
												<el-button @click="removeSelectItem(index)" type='text' >删除</el-button>
											</el-row>
										</div>
										<div v-if='item.selectSonItemList' class='childs2'>
											<vuedraggable filter=".pt1" v-model="item.selectSonItemList">  
												<transition-group>	
													<el-row class='itM12' v-for="(itemSon, indexSon) in item.selectSonItemList" :key="index + '' + indexSon">
														<el-input class="tempInput" v-model="itemSon.describe" maxlength="100" :show-word-limit="true" :placeholder="'描述'"></el-input>
														<el-input class="tempInput marlt12" v-model="itemSon.value" @input="inputValidate($event, index, indexSon)" maxlength="50" :show-word-limit="true" :style="{ marginLeft: '10px' }" :placeholder="'值'"></el-input>
														<el-button @click="addSelectSonItem(index)" type='text' >添加</el-button>
														<el-button @click="removeSelectSonItem(index)" type='text' >删除</el-button>
													</el-row>
												</transition-group>
											</vuedraggable>
										</div>
									</div>
								</transition-group>
							</vuedraggable>
							
							
							<!--<template v-for="(item, index) in inspectionTemplate.form.inspectionSelectItemList">
								<el-row :key="index" :style="{ marginBottom: '10px' }">
									<el-input class="tempInput" v-model="item.selectItemName" maxlength="50" :show-word-limit="true" :placeholder="'标题'"></el-input>
									<el-radio-group v-model="item.selectType" :style="{ marginLeft: '10px' }">
										<el-radio-button :label="1">单选</el-radio-button>
										<el-radio-button :label="2">多选</el-radio-button>
									</el-radio-group>
									<el-select v-model="item.isMandatory" :style="{ marginLeft: '10px' }" class='marlt12' :placeholder="'是否必选'">
										<el-option value="N" :label="'非必选'"></el-option>
										<el-option value="Y" :label="'必选'"></el-option>
									</el-select>
									<el-button @click="addSelectItem" type='text'  >添加</el-button>
									<el-button @click="removeSelectItem(index)" type='text' >删除</el-button>
								</el-row>
								<vuedraggable class="wrapper" v-model="item.selectSonItemList" :animation='200'><transition-group>	
									<template v-for="(itemSon, indexSon) in item.selectSonItemList">
										<el-row :key="index + '' + indexSon" :style="{ marginBottom: '10px' }">
											<el-input class="tempInput" v-model="itemSon.describe" maxlength="100" :show-word-limit="true" :placeholder="'描述'"></el-input>
											<el-input class="tempInput marlt12" v-model="itemSon.value" @input="inputValidate($event, index, indexSon)" maxlength="50" :show-word-limit="true" :style="{ marginLeft: '10px' }" :placeholder="'值'"></el-input>
											
											<el-button @click="addSelectSonItem(index)" type='text' >添加</el-button>
											<el-button @click="removeSelectSonItem(index)" type='text' >删除</el-button>
										</el-row>
									</template>
								</transition-group></vuedraggable>
							</template>-->
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="评分项">
							
							<vuedraggable class="wrapper" v-model="inspectionTemplate.form.inspectionScoreItemList" :animation='200'>
							    <transition-group>
										<template v-for="(item, index) in inspectionTemplate.form.inspectionScoreItemList">
											<el-row :key="index" class="dragIts pfIts">
												<el-input class="tempInput" v-model="item.scoreItemName" maxlength="50" :show-word-limit="true" :placeholder="$t('inspection.template.scoreContent')"></el-input>
												<el-input class="tempInput" v-model="item.scoreStandard" maxlength="100" :show-word-limit="true" :style="{ marginLeft: '10px' }" :placeholder="$t('inspection.template.scoreStandard')"></el-input>
												<el-select v-model="item.maxScore"  :style="{ marginLeft: '10px' }" :placeholder="$t('inspection.template.maxScore')" class="selectWidth marlt12">
													<el-option :value="2" :label="2"></el-option>
													<el-option :value="3" :label="3"></el-option>
													<el-option :value="4" :label="4"></el-option>
													<el-option :value="5" :label="5"></el-option>
												</el-select>
												<el-button @click="addScoreItem" type='text' class='marl12' v-if='index<1'>添加</el-button>
												<el-button @click="removeScoreItem(index)" type='text' >删除</el-button>
											</el-row>
										</template>
									</transition-group>
						  	</vuedraggable>
						</el-form-item>
					</el-row>
					<el-row class='copysWarp'>
						<el-form-item :label="$t('inspection.template.copyItem')">
							<!--抄录项-->
							
							<vuedraggable class="wrapper" v-model="inspectionTemplate.form.inspectionCopyItemList" :animation='200'>
							    <transition-group>
									<template v-for="(item, index) in inspectionTemplate.form.inspectionCopyItemList">
										<div :key="index" class="dragIts copyIts" >
											<el-input class="tempInput" v-model="item.copyItemName" maxlength="50" :show-word-limit="true" :placeholder="$t('inspection.template.copyContent')"></el-input>
											<el-select v-model="item.copyItemUnitCode"  :placeholder="$t('base.materiel.unit')" clearable class="selectWidth">
												<el-option v-for="(item,dd1) in unitOptions" :key="dd1" :label="item.itemName" :value="item.itemCode">
												</el-option>
											</el-select>
		
											<el-input class="tempInput" v-model="item.description"  maxlength="20" :show-word-limit="true" placeholder="阈值/备注"></el-input>
		
											<el-select v-model="item.necessaryFlag"  :placeholder="'是否必选'" class="selectWidth">
												<el-option value="N" :label="'非必选'"></el-option>
												<el-option value="Y" :label="'必选'"></el-option>
											</el-select>
											
											<el-button @click="addCopyItem(index)" type='text' v-if='index<1'>添加</el-button>
											<el-button @click="removeCopyItem(index)" type='text'>删除</el-button>
											<!--<el-button @click="upCopyItem(index)" class="el-icon-top" :style="{ marginLeft: '10px' }"></el-button>
											<el-button @click="downCopyItem(index)" class="el-icon-bottom"></el-button>
											<el-button @click="removeCopyItem(index)" class="el-icon-delete"></el-button>
											<el-button @click="addCopyItem" class="el-icon-plus"></el-button>-->
										</div>
									</template>
							    </transition-group>
						  	</vuedraggable>
							
							
							
						</el-form-item>
					</el-row>
					<el-row>
						<el-button type="primary" @click="handleTemplateSave">{{ $t("commons.save") }}
						</el-button>
					</el-row>
				</el-form>
			</el-tab-pane>
		</el-tabs>

		<!--巡检结果管理-->
		<el-dialog :title="$t('inspection.template.resultManager')" width="36%" :close-on-click-modal="false" center :show-close="false" :visible.sync="resultDialogDisable" :before-close="cancelResultSave">
			<el-row>
				{{ $t("inspection.template.resultItem") }}
			</el-row>
			<template v-for="(item, index) in inspectionResultItemManager">
				<el-row :key="index" :style="{ marginBottom: '10px' }">
					<el-radio v-model="inspectionResultIndex" :label="index">{{ $t("commons.defaultSelect") }}
					</el-radio>
					<el-input class="tempInput" v-model="item.resultItemName" maxlength="10" show-word-limit></el-input>
					<el-button :style="{ marginLeft: '10px' }" @click="removeResultItem(index)" class="el-icon-delete"></el-button>
					<el-button :style="{ marginLeft: '10px' }" @click="addResultItem" class="el-icon-plus"></el-button>
				</el-row>
			</template>
			<div slot="footer">
				<el-button @click="cancelResultSave">{{ $t("commons.cancel") }}
				</el-button>
				<el-button type="primary" @click="handleResultSave">
					{{ $t("commons.save") }}
				</el-button>
			</div>
		</el-dialog>

		<!--模版库管理-->
		<el-dialog :title="$t('inspection.template.libManager')" width="30%" :close-on-click-modal="false" center :show-close="false" :visible.sync="libraryDialogDisable" :before-close="cancelTemplateLibrarySave">
			<el-row>
				<el-input :placeholder="$t('inspection.template.pleaseInputName')" maxlength="50" :show-word-limit="true" class="tempInput" v-model="addInspectionTemplateLibrary" :style="{ width: '100%' }">
					<el-button slot="append" class='blueColor' :disabled='!addInspectionTemplateLibrary' @click="createTemplateLibrary">保存</el-button>
				</el-input>
			</el-row>
			<el-row :style="{ fontSize: '20px', margin: '10px 0' }">
				{{ $t("inspection.template.systemLib") }}
			</el-row>
			<div :style="{ background: '#DCDFE6', padding: '10px' }">
				<template v-for="(item, index) in templateLibrary">
					<el-row :key="index" :style="{ marginBottom: '10px' }">
						<el-input class="hidden" type="hidden" v-model="item.templateLibraryId"></el-input>
						<el-col :span="18">
							<template v-if="item.edit == true">
								<el-input class="tempInput" maxlength="80" :show-word-limit="true" v-model="item.templateLibraryName"></el-input>
							</template>
							<template v-else>
								{{ item.templateLibraryName }}
							</template>
						</el-col>
						<el-col :span="6" :style="{ textAlign: 'right' }">
							<template v-if="item.edit == true">
								<el-button size="mini" icon="el-icon-check" type="success"  circle @click="saveTemplateLibrary(item)">
								</el-button>
								<el-button size="mini" circle type="warning" @click="cancelSaveTemplateLibrary(item)" icon="el-icon-close">
								</el-button>
							</template>
							<template v-else>
								<el-button size="mini" icon="el-icon-edit" type="success" circle @click="editTemplateLibrary(item)">
								</el-button>
								<el-button size="mini" circle @click="removeLibraryItem(item.templateLibraryId, index)" icon="el-icon-delete">
								</el-button>
							</template>
						</el-col>
					</el-row>
				</template>
			</div>
			<div slot="footer">
				<el-button @click="cancelTemplateLibrarySave">{{ $t("commons.cancel") }}
				</el-button>
				<el-button type="primary" @click="cancelTemplateLibrarySave">
					{{ $t("commons.competed") }}
				</el-button>
			</div>
		</el-dialog>

		<!--模版预览-->
		<el-dialog class="backImage" width="400px" style="margin-top: -10px" :visible.sync="previewDialogDisable">
			<span>
        <div
          v-html="inspectionTemplate.form.templateName"
          :style="{
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '10px',
          }"
        ></div>
        <div class="getResult">
          {{ $t("inspection.template.recodeResult") }}
        </div>
        <div class="inspectionResult">
          <span class="firstSpan">{{ $t("inspection.result") }}</span>
			<!--<span class="secondSpan" v-html="inspectionTemplate.form.remarks" :title="inspectionTemplate.form.remarks"></span>-->
	</div>
	<template v-if="inspectionTemplate.form.inspectionCheckItemList.length > 0">
		<div>
			<p class="checkItem">{{ $t("inspection.item") }}</p>
			<ul class="checkUl">
				<li v-for="item in inspectionTemplate.form.inspectionCheckItemList" :key="item.sortField">
					<span class="checkItemSpan" :title="item.checkItemName">{{
                  item.checkItemName
                }}</span>
				</li>
			</ul>
		</div>
	</template>
	<template v-if="inspectionTemplate.form.inspectionSelectItemList.length > 0">
		<div>
			<p class="checkItem">选择项</p>
			<ul class="checkUl">
				<template v-for="(item, index) in inspectionTemplate.form
                  .inspectionSelectItemList">
					<li :key="item.sortField">
						<span class="checkItemSpan" :title="item.selectItemName">{{ index + 1 }}.{{ item.selectItemName }}</span
                  >
                  <span>
                    (
                    <template v-if="item.isMandatory == 'Y'"> 必填、 </template>
                    <template v-else> 选填、 </template>
                    <template v-if="item.selectType == 1"> 单选 </template>
                    <template v-else> 多选 </template>
                    )
                  </span>
					</li>
					<li v-for="itemSon in item.selectSonItemList" :key="item.sortField + '-' + itemSon.sortField">
						<p style="text-indent: 2em" class="checkItemSpan" :title="itemSon.describe">
							{{ itemSon.value }}.{{ itemSon.describe }}
						</p>
					</li>
				</template>
			</ul>
		</div>
	</template>
	<template v-if="inspectionTemplate.form.inspectionScoreItemList.length > 0">
		<div>
			<p class="checkItem">{{ $t("inspection.template.scoreItem") }}</p>
			<ul class="checkUl">
				<li v-for="item in inspectionTemplate.form.inspectionScoreItemList" :key="item.sortField">
					<span class="checkItemSpan" :title="item.scoreItemName">{{
                  item.scoreItemName
                }}</span>
				</li>
			</ul>
		</div>
	</template>
	<template v-if="inspectionTemplate.form.inspectionCopyItemList.length > 0">
		<div>
			<p class="checkItem">{{ $t("inspection.template.copyItem") }}</p>
			<ul class="checkUl">
				<li v-for="(item, index) in inspectionTemplate.form
                  .inspectionCopyItemList" :key="index">
					<span class="firstSpan" :title="item.copyItemName">{{
                  item.copyItemName
                }}</span>
					<span class="secondSpan" :title="item.copyItemUnitName">{{
                  item.copyItemUnitName
                }}</span>
				</li>
			</ul>
		</div>
	</template>
	</span>
	</el-dialog>
	</div>
</template>

<script>
	import {
		getTemplateLibrary,
		createTemplateLibrary,
		updateTemplateLibrary,
		deleteTemplateLibrary,
		copyTemplate,
		getTemplateList,
		getTemplateInfo,
		createTemplate,
		updateTemplate,
		deleteTemplate,
		findUnitCode,
	} from "@/api/business/inspection/tenant/template";
	import vuedraggable from 'vuedraggable'
	const defineTab = {
		tempTab: {
			tabName: "1",
		},
		editTab: {
			tabName: "2",
		},
	};

	export default {
		name: "InspectionTemplate",
		components: {vuedraggable},
		data() {
			return {
				openDelay: 1000,
				//编辑或新增模版标题
				editTitle: null,
				activeTabName: defineTab.tempTab.tabName,
				//模版库
				templateLibrary: [],
				//新增模版库名称
				addInspectionTemplateLibrary: null,
				//单位分类信息
				unitOptions: [],
				//模版编辑或新增tab页
				editTab: null,
				//检查结果项管理Dialog
				resultDialogDisable: false,
				//模版库管理Dialog
				libraryDialogDisable: false,
				//预览米板Dialog
				previewDialogDisable: false,
				//巡检模版表单数据
				inspectionTemplate: {
					list: [],
					listQuery: {
						templateLibraryId: null,
						templateName: null,
					},
					formRules: {
						templateName: [{
							required: true,
							message: '请输入模版名称',
							trigger: 'blur'
						}, ],
						//        templateLibraryId: [{ required: true, message: '请选择模版库', trigger: 'change' },],

					},
					form: {
						templateId: null,
						templateName: null,
						templateType: null,
						templateLibraryId: null,
						templateLibraryName: null,
						itemPlanId: null,
						remarks: null,
						isNotes: "N",
						lastUpdateDate: null,
						inspectionResultItem: this.$t("inspection.template.qualified"),
						inspectionCheckItemList: [{
							checkItemName: null,
							sortField: 1,
						}, ],
						inspectionSelectItemList: [{
							selectItemName: null,
							selectType: 1,
							isMandatory: "Y",
							selectSonItemList: [{
								describe: null,
								value: null,
								sortField: 1,
							}, ],
							sortField: 1,
						}, ],
						inspectionScoreItemList: [{
							scoreItemName: null,
							scoreStandard: null,
							maxScore: 5,
							sortField: 1,
						}, ],
						inspectionCopyItemList: [],
						inspectionResultItemList: [{
								resultItemName: this.$t("inspection.template.qualified"),
								defaultFlag: 1,
							},
							{
								resultItemName: this.$t("inspection.template.failed"),
								defaultFlag: 0,
							},
						],
					},
				},
				//巡检结果项管理列表
				inspectionResultItemManager: [],
				//巡检结果项下标
				inspectionResultIndex: 0,
			};
		},
		created() {
			this.getTemplateLibrary();
			this.getTemplate();
			this.getUnitOptions();
		},
		computed: {
			tempTab() {
				return defineTab.tempTab;
			},
		},
		methods: {
			checkOnMove(e){
				console.log('*****',e)
//				console.log('*****',JSON.parse(JSON.stringify(e)))
				return true;
				
			},
			//选择子项 值不可以输入逗号限制
			inputValidate(val, index, indexSon) {
				if(val != null) {
					if(val.indexOf(",") != -1 || val.indexOf("，") != -1) {
						let itemValue = val.replace(",", "");
						itemValue = itemValue.replace("，", "");
						this.inspectionTemplate.form.inspectionSelectItemList[
							index
						].selectSonItemList[indexSon].value = itemValue;
						this.$message({
							type: "info",
							message: this.$t("message.commaTips"),
						});
					}
				}
			},
			// 关闭项Tab
			removeTab(tabName) {
				this.activeTabName = defineTab.tempTab.tabName;
				if(tabName == "2") {
					this.editTab = null;
				}
			},
			//获取模版库
			getTemplateLibrary() {
				getTemplateLibrary().then((res) => {
					res.map((v) => {
						v.edit = false;
					});
					this.templateLibrary = res;
				});
			},
			//获取模版列表
			getTemplate() {
				getTemplateList(this.inspectionTemplate.listQuery).then((res) => {
					this.inspectionTemplate.list = res;
				});
			},
			//获取单位分类信息
			getUnitOptions() {
				//区域下拉框加载
				findUnitCode({
					classifyCode: "UNIT_LABEL"
				}).then((response) => {
					this.unitOptions = response;
				});
			},
			//删除模版
			deleteTemplate(templateId) {
				this.$confirm(
						this.$t("message.deleteConfirm"),
						this.$t("commons.warning"), {
							confirmButtonText: this.$t("commons.confirm"),
							cancalButtonText: this.$t("commons.cancel"),
							type: "warning",
						}
					)
					.then(() => {
						deleteTemplate({
								templateId: templateId
							})
							.then(() => {
								//成功提示
								this.$message({
									message: this.$t("message.deleteSuccess"),
									type: "success",
								});
								//删除成功后重新渲染table
								this.getTemplate();
							})
							.catch((error) => {
								console.log(`删除失败，原因 => ${error}`);
							});
					})
					.catch((error) => {
						console.log(`未删除，原因 => ${error}`);
					});
			},
			//复制模版
			copyTemplate(templateId) {
				this.$confirm(
						this.$t("inspection.template.ifCopyPrompt"),
						this.$t("commons.warning"), {
							confirmButtonText: this.$t("commons.confirm"),
							cancelButtonText: this.$t("commons.cancel"),
							type: "warning",
						}
					)
					.then(() => {
						copyTemplate({
								templateId: templateId
							})
							.then(() => {
								//成功提示
								this.$message({
									message: this.$t("message.operationSuccess"),
									type: "success",
								});
								//删除成功后重新渲染table
								this.getTemplate();
							})
							.catch((error) => {
								console.log(`复制失败，原因 => ${error}`);
							});
					})
					.catch((error) => {
						console.log(`未复制，原因 => ${error}`);
					});
			},
			//取消结果项保存
			cancelResultSave() {
				this.inspectionResultItemManager = [];
				this.resultDialogDisable = false;
			},
			//保存结果项
			handleResultSave() {
				this.inspectionTemplate.form.inspectionResultItemList =
					this.inspectionResultItemManager;
				this.inspectionTemplate.form.inspectionResultItem =
					this.inspectionResultItemManager[
						this.inspectionResultIndex
					].resultItemName;
				this.resultDialogDisable = false;
			},
			//删除结果项
			removeResultItem(index) {
				this.inspectionResultItemManager.splice(index, 1);
				if(this.inspectionResultItemManager.length == 0) {
					this.inspectionResultItemManager.push({
						resultItemName: null,
						defaultFlag: 0,
					});
				}
			},
			//添加结果项
			addResultItem() {
				this.inspectionResultItemManager.push({
					resultItemName: null,
					defaultFlag: 0,
				});
			},
			//删除检查项
			removeCheckItem(index) {
				this.inspectionTemplate.form.inspectionCheckItemList.splice(index, 1);
				if(this.inspectionTemplate.form.inspectionCheckItemList.length == 0) {
					this.inspectionTemplate.form.inspectionCheckItemList.push({
						checkItemName: null,
						sortField: 1,
					});
				}
			},
			//添加检查项
			addCheckItem() {
				this.inspectionTemplate.form.inspectionCheckItemList.push({
					checkItemName: null,
					sortField: this.inspectionTemplate.form.inspectionCheckItemList.length + 1,
				});
			},
			//删除评分项
			removeScoreItem(index) {
				this.inspectionTemplate.form.inspectionScoreItemList.splice(index, 1);
				if(this.inspectionTemplate.form.inspectionScoreItemList.length == 0) {
					this.inspectionTemplate.form.inspectionScoreItemList.push({
						scoreItemName: null,
						scoreStandard: null,
						maxScore: 5,
						sortField: 1,
					});
				}
			},
			//添加评分项
			addScoreItem() {
				this.inspectionTemplate.form.inspectionScoreItemList.push({
					scoreItemName: null,
					scoreStandard: null,
					maxScore: 5,
					sortField: this.inspectionTemplate.form.inspectionScoreItemList.length + 1,
				});
			},
			//删除选择项
			removeSelectItem(index) {
				this.inspectionTemplate.form.inspectionSelectItemList.splice(index, 1);
				if(this.inspectionTemplate.form.inspectionSelectItemList.length == 0) {
					this.inspectionTemplate.form.inspectionSelectItemList.push({
						selectItemName: null,
						selectType: 1,
						isMandatory: "Y",
						selectSonItemList: [{
							describe: null,
							value: null,
							sortField: 1,
						}, ],
						sortField: 1,
					});
				}
			},
			//添加选择项
			addSelectItem() {
				this.inspectionTemplate.form.inspectionSelectItemList.push({
					selectItemName: null,
					selectType: 1,
					isMandatory: "Y",
					selectSonItemList: [{
						describe: null,
						value: null,
						sortField: 1,
					}, ],
					sortField: this.inspectionTemplate.form.inspectionSelectItemList.length + 1,
				});
			},

			//删除选择子项
			removeSelectSonItem(index, indexSon) {
				this.inspectionTemplate.form.inspectionSelectItemList[
					index
				].selectSonItemList.splice(indexSon, 1);
				if(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList.length == 0
				) {
					this.inspectionTemplate.form.inspectionSelectItemList[
						index
					].selectSonItemList.push({
						describe: null,
						value: null,
						sortField: 1,
					});
				}
			},
			//添加选择子项
			addSelectSonItem(index) {
				this.inspectionTemplate.form.inspectionSelectItemList[
					index
				].selectSonItemList.push({
					describe: null,
					value: null,
					sortField: this.inspectionTemplate.form.inspectionSelectItemList[index]
						.selectSonItemList.length + 1,
				});
			},

			//删除抄录项
			removeCopyItem(index) {
				this.inspectionTemplate.form.inspectionCopyItemList.splice(index, 1);
				if(this.inspectionTemplate.form.inspectionCopyItemList.length == 0) {
					this.inspectionTemplate.form.inspectionCopyItemList.push({
						copyItemName: null,
						copyItemUnitCode: null,
						sortField: 1,
					});
				}
			},
			//添加抄录项
			addCopyItem() {
				this.inspectionTemplate.form.inspectionCopyItemList.push({
					copyItemName: null,
					copyItemUnitCode: null,
					necessaryFlag: 'Y',
					description: '',
					sortField: this.inspectionTemplate.form.inspectionCopyItemList.length + 1,
				});
			},
			//检查项上移
			upCheckItem(index) {
				if(
					this.inspectionTemplate.form.inspectionCheckItemList.length == 1 ||
					index == 0
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionCheckItemList[index - 1];
				this.$set(
					this.inspectionTemplate.form.inspectionCheckItemList,
					index - 1,
					this.inspectionTemplate.form.inspectionCheckItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionCheckItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionCheckItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionCheckItemList[
					index - 1
				].sortField = index;
			},
			//检查项下移
			downCheckItem(index) {
				if(
					this.inspectionTemplate.form.inspectionCheckItemList.length == 1 ||
					index == this.inspectionTemplate.form.inspectionCheckItemList.length - 1
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionCheckItemList[index + 1];
				this.$set(
					this.inspectionTemplate.form.inspectionCheckItemList,
					index + 1,
					this.inspectionTemplate.form.inspectionCheckItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionCheckItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionCheckItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionCheckItemList[
					index + 1
				].sortField = index + 2;
			},
			//评分项上移
			upScoreItem(index) {
				if(
					this.inspectionTemplate.form.inspectionScoreItemList.length == 1 ||
					index == 0
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionScoreItemList[index - 1];
				this.$set(
					this.inspectionTemplate.form.inspectionScoreItemList,
					index - 1,
					this.inspectionTemplate.form.inspectionScoreItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionScoreItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionScoreItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionScoreItemList[
					index - 1
				].sortField = index;
			},
			//评分项下移
			downScoreItem(index) {
				if(
					this.inspectionTemplate.form.inspectionScoreItemList.length == 1 ||
					index == this.inspectionTemplate.form.inspectionScoreItemList.length - 1
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionScoreItemList[index + 1];
				this.$set(
					this.inspectionTemplate.form.inspectionScoreItemList,
					index + 1,
					this.inspectionTemplate.form.inspectionScoreItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionScoreItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionScoreItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionScoreItemList[
					index + 1
				].sortField = index + 2;
			},
			//选择项上移
			upSelectItem(index) {
				if(
					this.inspectionTemplate.form.inspectionSelectItemList.length == 1 ||
					index == 0
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionSelectItemList[index - 1];
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList,
					index - 1,
					this.inspectionTemplate.form.inspectionSelectItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionSelectItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionSelectItemList[
					index - 1
				].sortField = index;
			},
			//选择项下移
			downSelectItem(index) {
				if(
					this.inspectionTemplate.form.inspectionSelectItemList.length == 1 ||
					index ==
					this.inspectionTemplate.form.inspectionSelectItemList.length - 1
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionSelectItemList[index + 1];
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList,
					index + 1,
					this.inspectionTemplate.form.inspectionSelectItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionSelectItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionSelectItemList[
					index + 1
				].sortField = index + 2;
			},

			//选择子项上移
			upSelectSonItem(index, indexSon) {
				if(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList.length == 1 ||
					indexSon == 0
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList[indexSon - 1];
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList,
					indexSon - 1,
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList[indexSon]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList,
					indexSon,
					tempList
				);
				this.inspectionTemplate.form.inspectionSelectItemList[
					index
				].selectSonItemList[indexSon].sortField = indexSon + 1;
				this.inspectionTemplate.form.inspectionSelectItemList[
					index
				].selectSonItemList[indexSon - 1].sortField = indexSon;
			},
			//选择子项下移
			downSelectSonItem(index, indexSon) {
				if(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList.length == 1 ||
					indexSon ==
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList.length -
					1
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList[indexSon + 1];
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList,
					indexSon + 1,
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList[indexSon]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionSelectItemList[index]
					.selectSonItemList,
					indexSon,
					tempList
				);
				this.inspectionTemplate.form.inspectionSelectItemList[
					index
				].selectSonItemList[indexSon].sortField = indexSon + 1;
				this.inspectionTemplate.form.inspectionSelectItemList[
					index
				].selectSonItemList[indexSon + 1].sortField = indexSon + 2;
			},

			//抄录项上移
			upCopyItem(index) {
				if(
					this.inspectionTemplate.form.inspectionCopyItemList.length == 1 ||
					index == 0
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionCopyItemList[index - 1];
				this.$set(
					this.inspectionTemplate.form.inspectionCopyItemList,
					index - 1,
					this.inspectionTemplate.form.inspectionCopyItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionCopyItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionCopyItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionCopyItemList[index - 1].sortField =
					index;
			},
			//抄录项下移
			downCopyItem(index) {
				if(
					this.inspectionTemplate.form.inspectionCopyItemList.length == 1 ||
					index == this.inspectionTemplate.form.inspectionCopyItemList.length - 1
				) {
					return;
				}
				let tempList =
					this.inspectionTemplate.form.inspectionCopyItemList[index + 1];
				this.$set(
					this.inspectionTemplate.form.inspectionCopyItemList,
					index + 1,
					this.inspectionTemplate.form.inspectionCopyItemList[index]
				);
				this.$set(
					this.inspectionTemplate.form.inspectionCopyItemList,
					index,
					tempList
				);
				this.inspectionTemplate.form.inspectionCopyItemList[index].sortField =
					index + 1;
				this.inspectionTemplate.form.inspectionCopyItemList[index + 1].sortField =
					index + 2;
			},
			validAddTempFrom() {
				// 校验新增模板输入规则是否正确
				return new Promise(resolve => {
					this.$refs.addTemplate.validate((valid) => {
						resolve(valid)
					});
				})
			},

			//保存模版
			async handleTemplateSave() {
				let isAllow = await this.validAddTempFrom();
				console.log('isAllow', isAllow)
				if(!isAllow) return;
				let reqDatas = JSON.parse(JSON.stringify(this.inspectionTemplate.form))
				reqDatas.inspectionResultItemList.map((v) => {
					if(
						v.resultItemName == reqDatas.inspectionResultItem
					) {
						v.defaultFlag = 1;
					} else {
						v.defaultFlag = 0;
					}
				});
				//抄录项
				console.log('form.inspectionCopyItemList',this.inspectionTemplate.form.inspectionCopyItemList)
				reqDatas.inspectionCopyItemList = []
				this.inspectionTemplate.form.inspectionCopyItemList.forEach(function(
					v,
					index,
					array
				) {
					if(v.copyItemName) {
						v.sortField=index+1;
						reqDatas.inspectionCopyItemList.push(v);
					}
				});

				//    this.inspectionTemplate.form.inspectionCopyItemList.forEach(function (
				//      v,
				//      index,
				//      array
				//    ) {
				//      if (
				//        (v.copyItemName == null || v.copyItemName == "") &&
				//        (v.copyItemUnitCode == null || v.copyItemUnitCode == "")
				//      ) {
				//      	console.log('cccc',v)
				//        array.splice(index, 1);
				//      }
				//    });
				//检查项
				reqDatas.inspectionCheckItemList = []
				this.inspectionTemplate.form.inspectionCheckItemList.forEach(function(
					v,
					index,
					array
				) {
					v.sortField=index+1;
					if(v.checkItemName && v.checkItemName) {
						reqDatas.inspectionCheckItemList.push(v)
					}
				});
				//    this.inspectionTemplate.form.inspectionCheckItemList.forEach(function (
				//      v,
				//      index,
				//      array
				//    ) {
				//      if (v.checkItemName == null || v.checkItemName == "") {
				//        array.splice(index, 1);
				//      }
				//    });
				//评分项
				reqDatas.inspectionScoreItemList = [];
				this.inspectionTemplate.form.inspectionScoreItemList.forEach(function(
					v,
					index,
					array
				) {
					v.sortField=index+1;
					console.log('------',v)
					if(v.scoreItemName && v.scoreItemName) {
						reqDatas.inspectionScoreItemList.push(v)
					}
				});
				//    this.inspectionTemplate.form.inspectionScoreItemList.forEach(function (
				//      v,
				//      index,
				//      array
				//    ) {
				//      if (v.scoreItemName == null || v.scoreItemName == "") {
				//        array.splice(index, 1);
				//      }
				//    });
				//选择项
				reqDatas.inspectionSelectItemList = [];
				this.inspectionTemplate.form.inspectionSelectItemList.forEach(function(
					v,
					index,
					array
				) {
					v.sortField=index+1;
					if(v.selectItemName) {
						let arr = [];
						v.selectSonItemList.forEach(function(vSon, indexSon, arraySon) {
							vSon.sortField=indexSon+1;
							if(vSon.describe) {
								arr.push(vSon);
							}
						});
						v.selectSonItemList = arr;
						reqDatas.inspectionSelectItemList.push(v)
					}
				});
				//    this.inspectionTemplate.form.inspectionSelectItemList.forEach(function (
				//      v,
				//      index,
				//      array
				//    ) {
				//      v.selectSonItemList.forEach(function (vSon, indexSon, arraySon) {
				//        if (vSon.describe == null || vSon.describe == "") {
				//          arraySon.splice(indexSon, 1);
				//        }
				//      });
				//      if (v.selectItemName == null || v.selectItemName == "") {
				//        array.splice(index, 1);
				//      }
				//    });

				//			console.log('this.inspectionTemplate.form',JSON.parse(JSON.stringify(reqDatas)))
				//			return;
				if(reqDatas.templateId == null) {
					createTemplate(reqDatas)
						.then(() => {
							// 成功提示
							this.$message({
								message: this.$t("message.saveSuccess"),
								type: "success",
							});
							this.removeTab(defineTab.editTab.tabName);
							this.getTemplate();
						})
						.catch((error) => {
							console.log(`保存失败，原因 => ${error}`);
						});
				} else {
					this.$confirm(
							this.$t("message.editConfirm"),
							this.$t("commons.warning"), {
								confirmButtonText: this.$t("commons.confirm"),
								cancelButtonText: this.$t("commons.cancel"),
								type: "warning",
							}
						)
						.then(() => {
							updateTemplate(reqDatas)
								.then(() => {
									// 成功提示
									this.$message({
										message: this.$t("message.saveSuccess"),
										type: "success",
									});
									this.removeTab(defineTab.editTab.tabName);
									this.getTemplate();
								})
								.catch((error) => {
									console.log(`保存失败，原因 => ${error}`);
								});
						})
						.catch((error) => {
							console.log(`未修改，原因 => ${error}`);
						});
				}
			},
			//新建模版
			createTemplate() {
				this.clearTemplateForm();
				this.editTab = defineTab.editTab;
				this.editTitle = this.$t("inspection.template.add");
				this.inspectionResultItemManager =
					this.inspectionTemplate.form.inspectionResultItemList;
				this.activeTabName = defineTab.editTab.tabName;
			},
			//预览模版
			templatePreview(templateId) {
				getTemplateInfo({
					templateId: templateId
				}).then((res) => {
					this.inspectionTemplate.form = res;
					this.previewDialogDisable = true;
				});
			},
			//获取模版信息
			getTemplateDetail(templateId) {
				getTemplateInfo({
					templateId: templateId
				}).then((res) => {

					if(res.inspectionResultItemList.length == 0) {
						res.inspectionResultItemList.push({
							resultItemName: null,
							defaultFlag: 0,
						});
					}
					if(res.inspectionCheckItemList.length == 0) {
						res.inspectionCheckItemList.push({
							checkItemName: null,
							sortField: 1,
						});
					}
					if(res.inspectionSelectItemList.length == 0) {
						res.inspectionSelectItemList.push({
							selectItemName: null,
							selectType: 1,
							isMandatory: "Y",
							selectSonItemList: [{
								describe: null,
								value: null,
								sortField: 1,
							}, ],
							sortField: 1,
						});
					}
					if(res.inspectionScoreItemList.length == 0) {
						res.inspectionScoreItemList.push({
							scoreItemName: null,
							scoreStandard: null,
							maxScore: 5,
							sortField: 1,
						});
					}

					this.inspectionTemplate.form = res;
					if(res.inspectionCopyItemList.length == 0) {
						this.addCopyItem(); //没有的话 默认添加一行
					}
					res.inspectionResultItemList.forEach((v) => {
						if(v.defaultFlag == 1) {
							this.inspectionTemplate.form.inspectionResultItem =
								v.resultItemName;
							return;
						}
					});
					this.editTab = defineTab.editTab;
					this.editTitle = this.$t("inspection.template.edit");
					this.inspectionResultItemManager =
						this.inspectionTemplate.form.inspectionResultItemList;
					this.activeTabName = defineTab.editTab.tabName;
				});
			},
			//编辑结果项
			editResultItem() {
				this.inspectionResultItemManager =
					this.inspectionTemplate.form.inspectionResultItemList;
				this.inspectionResultItemManager.forEach((v, index) => {
					if(
						v.resultItemName == this.inspectionTemplate.form.inspectionResultItem
					) {
						this.inspectionResultIndex = index;
						return;
					}
				});
				this.resultDialogDisable = true;
			},
			//新建模版库
			createTemplateLibrary() {
				console.log("x");
				if(this.addInspectionTemplateLibrary == null) {
					this.$message({
						message: this.$t("inspection.template.notNull"),
						type: "info",
					});
					return;
				}
				createTemplateLibrary({
						templateLibraryName: this.addInspectionTemplateLibrary,
					})
					.then(() => {
						// 成功提示
						this.$message({
							message: this.$t("message.operationSuccess"),
							type: "success",
						});
						this.getTemplateLibrary();
					})
					.catch((error) => {
						console.log(`创建失败，原因 => ${error}`);
					});
			},
			//删除模版库
			removeLibraryItem(templateLibraryId, index) {
				this.$confirm(
						this.$t("message.deleteConfirm"),
						this.$t("commons.warning"), {
							confirmButtonText: this.$t("commons.confirm"),
							cancelButtonText: this.$t("commons.cancel"),
							type: "warning",
						}
					)
					.then(() => {
						getTemplateList({
							templateLibraryId: templateLibraryId
						}).then(
							(res) => {
								if(res.length == 0) {
									deleteTemplateLibrary({
											templateLibraryId: templateLibraryId
										})
										.then(() => {
											//成功提示
											this.$message({
												message: this.$t("message.deleteSuccess"),
												type: "success",
											});
											//删除成功后重新渲染table
											this.templateLibrary.splice(index, 1);
										})
										.catch((error) => {
											console.log(`删除失败，原因 => ${error}`);
										});
								} else {
									this.$message({
										message: this.$t("inspection.template.containPrompt"),
										type: "info",
									});
								}
							}
						);
					})
					.catch((error) => {
						console.log(`未删除，原因 => ${error}`);
					});
			},
			//编辑模版库
			editTemplateLibrary(item) {
				item.originalTemplateLibraryName = item.templateLibraryName;
				item.edit = true;
			},
			//取消模版库编辑
			cancelSaveTemplateLibrary(item) {
				item.templateLibraryName = item.originalTemplateLibraryName;
				item.edit = false;
			},
			//保存模版库
			saveTemplateLibrary(item) {
				if(!item.templateLibraryName){
					this.$message('不能为空，请输入')
					return;
				}
				updateTemplateLibrary(item)
					.then(() => {
						// 成功提示
						this.$message({
							message: this.$t("message.saveSuccess"),
							type: "success",
						});
						item.edit = false;
					})
					.catch((error) => {
						console.log(`保存失败，原因 => ${error}`);
					});
			},
			//关闭模版库管理
			cancelTemplateLibrarySave() {
				this.addInspectionTemplateLibrary=null;
				this.libraryDialogDisable = false;
			},
			//清空表单数据
			clearTemplateForm() {
				this.inspectionTemplate.form = {
					templateId: null,
					templateName: null,
					templateType: null,
					templateLibraryId: null,
					templateLibraryName: null,
					itemPlanId: null,
					remarks: null,
					isNotes: "N",
					lastUpdateDate: null,
					inspectionResultItem: this.$t("inspection.template.qualified"),
					inspectionCheckItemList: [{
						checkItemName: null,
						sortField: 1,
					}, ],
					inspectionSelectItemList: [{
						selectItemName: null,
						selectType: 1,
						isMandatory: "Y",
						selectSonItemList: [{
							describe: null,
							value: null,
							sortField: 1,
						}, ],
						sortField: 1,
					}, ],
					inspectionScoreItemList: [{
						scoreItemName: null,
						scoreStandard: null,
						maxScore: 5,
						sortField: 1,
					}, ],
					inspectionCopyItemList: [],
					inspectionResultItemList: [{
							resultItemName: this.$t("inspection.template.qualified"),
							defaultFlag: 1,
						},
						{
							resultItemName: this.$t("inspection.template.failed"),
							defaultFlag: 0,
						},
					],
				};
				this.addCopyItem(); //默认添加一行
			},
		},
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.backImage {
		background: url("../../../../../assets/images/phone.png") no-repeat center center;
	}
	
	.selectWidth {
		width: 130px;
	}
	
	.cardHeader {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	
	.templateInfoUl {
		// height: 129rpx?;
		list-style-type: none;
		padding-inline-start: 0px;
		padding-top: 0px;
		margin-top: 0px;
		.remark-style {
			height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
	
	.templateInfoUl li {
		margin-bottom: 10px;
	}
	
	.templateCard {
		margin-top: 20px;
		margin-right: 20px;
	}
	
	.tempInput {
		width: 300px;
	}
	.marlt12{
		margin-right: 10px;
	}
	.copysWarp{
		.selectWidth,.tempInput {
			margin-right: 10px;
		}
	} 
	
	.getResult {
		background: #f0f1f5;
		height: 30px;
		line-height: 30px;
		padding-left: 20px;
	}
	
	.inspectionResult {
		height: 40px;
		line-height: 40px;
		padding: 0 40px;
	}
	
	.checkItemSpan {
		width: 100%;
		overflow: hidden;
		/*超出的部分隐藏起来。*/
		white-space: nowrap;
		/*不显示的地方用省略号...代替*/
		text-overflow: ellipsis;
		/* 支持 IE */
		-webkit-box-orient: vertical;
	}
	
	.firstSpan {
		width: 50%;
		overflow: hidden;
		/*超出的部分隐藏起来。*/
		white-space: nowrap;
		/*不显示的地方用省略号...代替*/
		text-overflow: ellipsis;
		/* 支持 IE */
	}
	.pfIts{
		margin-bottom: 10px;
	}
	/*.dragIts{
		margin-bottom: 10px;
		padding: 6px 12px;
		border: 1px solid #DCDFE6;
		.selectWidth,.tempInput{
			border-bottom: 1px solid #409EFF;
			margin-right: 12px;
		}
		
	}*/
	.copyIts{
		margin-bottom: 10px;
	}
	.secondSpan {
		width: 50%;
		float: right;
	}
	
	.checkItem {
		background: #f0f1f5;
		margin: 0;
		height: 30px;
		line-height: 30px;
		padding-left: 20px;
	}
	
	.checkUl {
		margin: 0;
		padding-left: 0;
	}
	
	.checkUl li {
		list-style: none;
		height: 40px;
		line-height: 40px;
		padding: 0 40px;
	}
	.c_dragIts{
		box-shadow: 1px 1px 1px 1px #E0E0E0;
		margin: 12px 0;
		padding: 12px;
    padding-bottom: 1px;
    box-shadow: 0px 0px 6px 1px #eee;
    width: 99%;
    border-radius: 4px;
	}
	.itM12{
		margin:10px 0;
	}
</style>
<style>
	/*.dragIts .el-input--medium .el-input__inner{
	    border: none !important;
	    border-bottom: 1px solid #ccc;
	 	background: none !important;
	}*/
	.el-dialog__body {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}
	
	.backImage>.el-dialog {
		background: none;
		box-shadow: none;
		height: 700px;
		overflow-y: scroll;
	}
	.blueColor.el-button{
		background-color: #1a4cec !important;
	    color: white !important;
	    border-radius: 0 !important;
	    border-color: #1a4cec !important;
	}
</style>