var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.tempTab.tabName,
              attrs: {
                label: _vm.$t("inspection.template.manager"),
                name: _vm.tempTab.tabName,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 9 } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "inspection.template.pleaseInputName"
                            ),
                            maxlength: "80",
                          },
                          model: {
                            value:
                              _vm.inspectionTemplate.listQuery.templateName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inspectionTemplate.listQuery,
                                "templateName",
                                $$v
                              )
                            },
                            expression:
                              "inspectionTemplate.listQuery.templateName",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectWidth",
                              attrs: {
                                slot: "prepend",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "inspection.template.pleaseSelectLib"
                                ),
                              },
                              on: { change: _vm.getTemplate },
                              slot: "prepend",
                              model: {
                                value:
                                  _vm.inspectionTemplate.listQuery
                                    .templateLibraryId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.inspectionTemplate.listQuery,
                                    "templateLibraryId",
                                    $$v
                                  )
                                },
                                expression:
                                  "inspectionTemplate.listQuery.templateLibraryId",
                              },
                            },
                            _vm._l(_vm.templateLibrary, function (item) {
                              return _c("el-option", {
                                key: item.templateLibraryId,
                                attrs: {
                                  label: item.templateLibraryName,
                                  value: item.templateLibraryId,
                                },
                              })
                            }),
                            1
                          ),
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.getTemplate },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 14, offset: 1 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.libraryDialogDisable = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("inspection.template.editLib")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.createTemplate },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("inspection.template.add")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _vm._l(_vm.inspectionTemplate.list, function (item) {
                    return [
                      _c(
                        "el-col",
                        {
                          key: item.templateId,
                          staticClass: "templateCard",
                          attrs: { md: 5 },
                        },
                        [
                          _c(
                            "el-card",
                            { staticClass: "box-card" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "cardHeader",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.templateName || " ")),
                                  ]),
                                ]
                              ),
                              _c(
                                "ul",
                                { staticClass: "templateInfoUl" },
                                [
                                  item.remarks == "" || item.remarks == null
                                    ? [
                                        _c(
                                          "li",
                                          { staticClass: "remark-style" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.$t(
                                                    "inspection.template.notRemark"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "li",
                                          { staticClass: "remark-style" },
                                          [_vm._v(_vm._s(item.remarks))]
                                        ),
                                      ],
                                  item.lastUpdateDate != null
                                    ? _c("li", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.lastUpdateDate,
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("commons.preview"),
                                        placement: "bottom",
                                        "open-delay": _vm.openDelay,
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "el-icon-view",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.templatePreview(
                                              item.templateId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("commons.copy"),
                                        placement: "bottom",
                                        "open-delay": _vm.openDelay,
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "el-icon-document-copy",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyTemplate(
                                              item.templateId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("commons.edit"),
                                        placement: "bottom",
                                        "open-delay": _vm.openDelay,
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "el-icon-edit",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getTemplateDetail(
                                              item.templateId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("commons.delete"),
                                        placement: "bottom",
                                        "open-delay": _vm.openDelay,
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "el-icon-delete",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteTemplate(
                                              item.templateId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.editTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.editTab.tabName,
                  attrs: {
                    label: _vm.editTitle,
                    name: _vm.editTab.tabName,
                    closable: "",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addTemplate",
                      attrs: {
                        role: "templateForm",
                        rules: _vm.inspectionTemplate.formRules,
                        model: _vm.inspectionTemplate.form,
                        "label-width": "80px",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("inspection.template.lib"),
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "hidden",
                                attrs: { type: "hidden" },
                                model: {
                                  value: _vm.inspectionTemplate.form.templateId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionTemplate.form,
                                      "templateId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "inspectionTemplate.form.templateId",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticClass: "tempInput",
                                  attrs: {
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "inspection.template.pleaseSelectLib"
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.inspectionTemplate.form
                                        .templateLibraryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionTemplate.form,
                                        "templateLibraryId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionTemplate.form.templateLibraryId",
                                  },
                                },
                                _vm._l(_vm.templateLibrary, function (item) {
                                  return _c("el-option", {
                                    key: item.templateLibraryId,
                                    attrs: {
                                      label: item.templateLibraryName,
                                      value: item.templateLibraryId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateName",
                                label: _vm.$t(
                                  "inspection.template.templateName"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "tempInput",
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": true,
                                },
                                model: {
                                  value:
                                    _vm.inspectionTemplate.form.templateName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionTemplate.form,
                                      "templateName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "inspectionTemplate.form.templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("inspection.template.remark"),
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "tempInput",
                                attrs: {
                                  maxlength: "160",
                                  "show-word-limit": true,
                                },
                                model: {
                                  value: _vm.inspectionTemplate.form.remarks,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionTemplate.form,
                                      "remarks",
                                      $$v
                                    )
                                  },
                                  expression: "inspectionTemplate.form.remarks",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("inspection.template.isNotes"),
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": "Y",
                                  "inactive-value": "N",
                                },
                                model: {
                                  value: _vm.inspectionTemplate.form.isNotes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionTemplate.form,
                                      "isNotes",
                                      $$v
                                    )
                                  },
                                  expression: "inspectionTemplate.form.isNotes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "inspection.template.checkResult"
                                ),
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "tempInput",
                                attrs: { readonly: true },
                                model: {
                                  value:
                                    _vm.inspectionTemplate.form
                                      .inspectionResultItem,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionTemplate.form,
                                      "inspectionResultItem",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "inspectionTemplate.form.inspectionResultItem",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  style: { marginLeft: "10px" },
                                  on: { click: _vm.editResultItem },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("inspection.template.editItem")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("inspection.template.checkItem"),
                              },
                            },
                            [
                              _c(
                                "vuedraggable",
                                {
                                  staticClass: "wrapper",
                                  attrs: { animation: 200 },
                                  model: {
                                    value:
                                      _vm.inspectionTemplate.form
                                        .inspectionCheckItemList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionTemplate.form,
                                        "inspectionCheckItemList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionTemplate.form.inspectionCheckItemList",
                                  },
                                },
                                [
                                  _c(
                                    "transition-group",
                                    [
                                      _vm._l(
                                        _vm.inspectionTemplate.form
                                          .inspectionCheckItemList,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "el-row",
                                              {
                                                key: index,
                                                style: { marginBottom: "10px" },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass:
                                                    "tempInput marlt12",
                                                  attrs: {
                                                    maxlength: "50",
                                                    "show-word-limit": true,
                                                    placeholder: _vm.$t(
                                                      "inspection.template.checkContent"
                                                    ),
                                                  },
                                                  model: {
                                                    value: item.checkItemName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "checkItemName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.checkItemName",
                                                  },
                                                }),
                                                index < 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click:
                                                            _vm.addCheckItem,
                                                        },
                                                      },
                                                      [_vm._v("添加")]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeCheckItem(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择项" } },
                            [
                              _c(
                                "vuedraggable",
                                {
                                  staticClass: "wrapper",
                                  attrs: {
                                    filter: ".childs2",
                                    animation: 200,
                                    move: _vm.checkOnMove,
                                  },
                                  model: {
                                    value:
                                      _vm.inspectionTemplate.form
                                        .inspectionSelectItemList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionTemplate.form,
                                        "inspectionSelectItemList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionTemplate.form.inspectionSelectItemList",
                                  },
                                },
                                [
                                  _c(
                                    "transition-group",
                                    _vm._l(
                                      _vm.inspectionTemplate.form
                                        .inspectionSelectItemList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "c_dragIts",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "pt1" },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c("el-input", {
                                                      staticClass: "tempInput",
                                                      attrs: {
                                                        maxlength: "50",
                                                        "show-word-limit": true,
                                                        placeholder: "标题",
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectItemName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectItemName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectItemName",
                                                      },
                                                    }),
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        style: {
                                                          marginLeft: "10px",
                                                        },
                                                        model: {
                                                          value:
                                                            item.selectType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "selectType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.selectType",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio-button",
                                                          {
                                                            attrs: { label: 1 },
                                                          },
                                                          [_vm._v("单选")]
                                                        ),
                                                        _c(
                                                          "el-radio-button",
                                                          {
                                                            attrs: { label: 2 },
                                                          },
                                                          [_vm._v("多选")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass: "marlt12",
                                                        style: {
                                                          marginLeft: "10px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "是否必选",
                                                        },
                                                        model: {
                                                          value:
                                                            item.isMandatory,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "isMandatory",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.isMandatory",
                                                        },
                                                      },
                                                      [
                                                        _c("el-option", {
                                                          attrs: {
                                                            value: "N",
                                                            label: "非必选",
                                                          },
                                                        }),
                                                        _c("el-option", {
                                                          attrs: {
                                                            value: "Y",
                                                            label: "必选",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click:
                                                            _vm.addSelectItem,
                                                        },
                                                      },
                                                      [_vm._v("添加")]
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeSelectItem(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            item.selectSonItemList
                                              ? _c(
                                                  "div",
                                                  { staticClass: "childs2" },
                                                  [
                                                    _c(
                                                      "vuedraggable",
                                                      {
                                                        attrs: {
                                                          filter: ".pt1",
                                                        },
                                                        model: {
                                                          value:
                                                            item.selectSonItemList,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "selectSonItemList",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.selectSonItemList",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "transition-group",
                                                          _vm._l(
                                                            item.selectSonItemList,
                                                            function (
                                                              itemSon,
                                                              indexSon
                                                            ) {
                                                              return _c(
                                                                "el-row",
                                                                {
                                                                  key:
                                                                    index +
                                                                    "" +
                                                                    indexSon,
                                                                  staticClass:
                                                                    "itM12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "tempInput",
                                                                      attrs: {
                                                                        maxlength:
                                                                          "100",
                                                                        "show-word-limit": true,
                                                                        placeholder:
                                                                          "描述",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          itemSon.describe,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              itemSon,
                                                                              "describe",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "itemSon.describe",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "tempInput marlt12",
                                                                      style: {
                                                                        marginLeft:
                                                                          "10px",
                                                                      },
                                                                      attrs: {
                                                                        maxlength:
                                                                          "50",
                                                                        "show-word-limit": true,
                                                                        placeholder:
                                                                          "值",
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.inputValidate(
                                                                              $event,
                                                                              index,
                                                                              indexSon
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          itemSon.value,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              itemSon,
                                                                              "value",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "itemSon.value",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addSelectSonItem(
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "添加"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeSelectSonItem(
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "删除"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "评分项" } },
                            [
                              _c(
                                "vuedraggable",
                                {
                                  staticClass: "wrapper",
                                  attrs: { animation: 200 },
                                  model: {
                                    value:
                                      _vm.inspectionTemplate.form
                                        .inspectionScoreItemList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionTemplate.form,
                                        "inspectionScoreItemList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionTemplate.form.inspectionScoreItemList",
                                  },
                                },
                                [
                                  _c(
                                    "transition-group",
                                    [
                                      _vm._l(
                                        _vm.inspectionTemplate.form
                                          .inspectionScoreItemList,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "el-row",
                                              {
                                                key: index,
                                                staticClass: "dragIts pfIts",
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "tempInput",
                                                  attrs: {
                                                    maxlength: "50",
                                                    "show-word-limit": true,
                                                    placeholder: _vm.$t(
                                                      "inspection.template.scoreContent"
                                                    ),
                                                  },
                                                  model: {
                                                    value: item.scoreItemName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "scoreItemName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.scoreItemName",
                                                  },
                                                }),
                                                _c("el-input", {
                                                  staticClass: "tempInput",
                                                  style: { marginLeft: "10px" },
                                                  attrs: {
                                                    maxlength: "100",
                                                    "show-word-limit": true,
                                                    placeholder: _vm.$t(
                                                      "inspection.template.scoreStandard"
                                                    ),
                                                  },
                                                  model: {
                                                    value: item.scoreStandard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "scoreStandard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.scoreStandard",
                                                  },
                                                }),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass:
                                                      "selectWidth marlt12",
                                                    style: {
                                                      marginLeft: "10px",
                                                    },
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "inspection.template.maxScore"
                                                      ),
                                                    },
                                                    model: {
                                                      value: item.maxScore,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "maxScore",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.maxScore",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 2,
                                                        label: 2,
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 3,
                                                        label: 3,
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 4,
                                                        label: 4,
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: 5,
                                                        label: 5,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                index < 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "marl12",
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click:
                                                            _vm.addScoreItem,
                                                        },
                                                      },
                                                      [_vm._v("添加")]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeScoreItem(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "copysWarp" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("inspection.template.copyItem"),
                              },
                            },
                            [
                              _c(
                                "vuedraggable",
                                {
                                  staticClass: "wrapper",
                                  attrs: { animation: 200 },
                                  model: {
                                    value:
                                      _vm.inspectionTemplate.form
                                        .inspectionCopyItemList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionTemplate.form,
                                        "inspectionCopyItemList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionTemplate.form.inspectionCopyItemList",
                                  },
                                },
                                [
                                  _c(
                                    "transition-group",
                                    [
                                      _vm._l(
                                        _vm.inspectionTemplate.form
                                          .inspectionCopyItemList,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "dragIts copyIts",
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "tempInput",
                                                  attrs: {
                                                    maxlength: "50",
                                                    "show-word-limit": true,
                                                    placeholder: _vm.$t(
                                                      "inspection.template.copyContent"
                                                    ),
                                                  },
                                                  model: {
                                                    value: item.copyItemName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "copyItemName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.copyItemName",
                                                  },
                                                }),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "selectWidth",
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t(
                                                          "base.materiel.unit"
                                                        ),
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        item.copyItemUnitCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "copyItemUnitCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.copyItemUnitCode",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.unitOptions,
                                                    function (item, dd1) {
                                                      return _c("el-option", {
                                                        key: dd1,
                                                        attrs: {
                                                          label: item.itemName,
                                                          value: item.itemCode,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c("el-input", {
                                                  staticClass: "tempInput",
                                                  attrs: {
                                                    maxlength: "20",
                                                    "show-word-limit": true,
                                                    placeholder: "阈值/备注",
                                                  },
                                                  model: {
                                                    value: item.description,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.description",
                                                  },
                                                }),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "selectWidth",
                                                    attrs: {
                                                      placeholder: "是否必选",
                                                    },
                                                    model: {
                                                      value: item.necessaryFlag,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "necessaryFlag",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.necessaryFlag",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: "N",
                                                        label: "非必选",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value: "Y",
                                                        label: "必选",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                index < 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addCopyItem(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加")]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeCopyItem(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplateSave },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("commons.save")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("inspection.template.resultManager"),
            width: "36%",
            "close-on-click-modal": false,
            center: "",
            "show-close": false,
            visible: _vm.resultDialogDisable,
            "before-close": _vm.cancelResultSave,
          },
          on: {
            "update:visible": function ($event) {
              _vm.resultDialogDisable = $event
            },
          },
        },
        [
          _c("el-row", [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.$t("inspection.template.resultItem")) +
                "\n\t\t\t"
            ),
          ]),
          _vm._l(_vm.inspectionResultItemManager, function (item, index) {
            return [
              _c(
                "el-row",
                { key: index, style: { marginBottom: "10px" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: index },
                      model: {
                        value: _vm.inspectionResultIndex,
                        callback: function ($$v) {
                          _vm.inspectionResultIndex = $$v
                        },
                        expression: "inspectionResultIndex",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("commons.defaultSelect")) + "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _c("el-input", {
                    staticClass: "tempInput",
                    attrs: { maxlength: "10", "show-word-limit": "" },
                    model: {
                      value: item.resultItemName,
                      callback: function ($$v) {
                        _vm.$set(item, "resultItemName", $$v)
                      },
                      expression: "item.resultItemName",
                    },
                  }),
                  _c("el-button", {
                    staticClass: "el-icon-delete",
                    style: { marginLeft: "10px" },
                    on: {
                      click: function ($event) {
                        return _vm.removeResultItem(index)
                      },
                    },
                  }),
                  _c("el-button", {
                    staticClass: "el-icon-plus",
                    style: { marginLeft: "10px" },
                    on: { click: _vm.addResultItem },
                  }),
                ],
                1
              ),
            ]
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelResultSave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n\t\t\t\t"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleResultSave },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.$t("commons.save")) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("inspection.template.libManager"),
            width: "30%",
            "close-on-click-modal": false,
            center: "",
            "show-close": false,
            visible: _vm.libraryDialogDisable,
            "before-close": _vm.cancelTemplateLibrarySave,
          },
          on: {
            "update:visible": function ($event) {
              _vm.libraryDialogDisable = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-input",
                {
                  staticClass: "tempInput",
                  style: { width: "100%" },
                  attrs: {
                    placeholder: _vm.$t("inspection.template.pleaseInputName"),
                    maxlength: "50",
                    "show-word-limit": true,
                  },
                  model: {
                    value: _vm.addInspectionTemplateLibrary,
                    callback: function ($$v) {
                      _vm.addInspectionTemplateLibrary = $$v
                    },
                    expression: "addInspectionTemplateLibrary",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "blueColor",
                      attrs: {
                        slot: "append",
                        disabled: !_vm.addInspectionTemplateLibrary,
                      },
                      on: { click: _vm.createTemplateLibrary },
                      slot: "append",
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row", { style: { fontSize: "20px", margin: "10px 0" } }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.$t("inspection.template.systemLib")) +
                "\n\t\t\t"
            ),
          ]),
          _c(
            "div",
            { style: { background: "#DCDFE6", padding: "10px" } },
            [
              _vm._l(_vm.templateLibrary, function (item, index) {
                return [
                  _c(
                    "el-row",
                    { key: index, style: { marginBottom: "10px" } },
                    [
                      _c("el-input", {
                        staticClass: "hidden",
                        attrs: { type: "hidden" },
                        model: {
                          value: item.templateLibraryId,
                          callback: function ($$v) {
                            _vm.$set(item, "templateLibraryId", $$v)
                          },
                          expression: "item.templateLibraryId",
                        },
                      }),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          item.edit == true
                            ? [
                                _c("el-input", {
                                  staticClass: "tempInput",
                                  attrs: {
                                    maxlength: "80",
                                    "show-word-limit": true,
                                  },
                                  model: {
                                    value: item.templateLibraryName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "templateLibraryName", $$v)
                                    },
                                    expression: "item.templateLibraryName",
                                  },
                                }),
                              ]
                            : [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(item.templateLibraryName) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "el-col",
                        { style: { textAlign: "right" }, attrs: { span: 6 } },
                        [
                          item.edit == true
                            ? [
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-check",
                                    type: "success",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveTemplateLibrary(item)
                                    },
                                  },
                                }),
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    circle: "",
                                    type: "warning",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelSaveTemplateLibrary(item)
                                    },
                                  },
                                }),
                              ]
                            : [
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-edit",
                                    type: "success",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editTemplateLibrary(item)
                                    },
                                  },
                                }),
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    circle: "",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeLibraryItem(
                                        item.templateLibraryId,
                                        index
                                      )
                                    },
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { on: { click: _vm.cancelTemplateLibrarySave } },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n\t\t\t\t")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.cancelTemplateLibrarySave },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.$t("commons.competed")) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "backImage",
          staticStyle: { "margin-top": "-10px" },
          attrs: { width: "400px", visible: _vm.previewDialogDisable },
          on: {
            "update:visible": function ($event) {
              _vm.previewDialogDisable = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("div", {
                style: {
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "10px",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.inspectionTemplate.form.templateName),
                },
              }),
              _c("div", { staticClass: "getResult" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("inspection.template.recodeResult")) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "inspectionResult" }, [
                _c("span", { staticClass: "firstSpan" }, [
                  _vm._v(_vm._s(_vm.$t("inspection.result"))),
                ]),
              ]),
              _vm.inspectionTemplate.form.inspectionCheckItemList.length > 0
                ? [
                    _c("div", [
                      _c("p", { staticClass: "checkItem" }, [
                        _vm._v(_vm._s(_vm.$t("inspection.item"))),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "checkUl" },
                        _vm._l(
                          _vm.inspectionTemplate.form.inspectionCheckItemList,
                          function (item) {
                            return _c("li", { key: item.sortField }, [
                              _c(
                                "span",
                                {
                                  staticClass: "checkItemSpan",
                                  attrs: { title: item.checkItemName },
                                },
                                [_vm._v(_vm._s(item.checkItemName))]
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm.inspectionTemplate.form.inspectionSelectItemList.length > 0
                ? [
                    _c("div", [
                      _c("p", { staticClass: "checkItem" }, [_vm._v("选择项")]),
                      _c(
                        "ul",
                        { staticClass: "checkUl" },
                        [
                          _vm._l(
                            _vm.inspectionTemplate.form
                              .inspectionSelectItemList,
                            function (item, index) {
                              return [
                                _c("li", { key: item.sortField }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "checkItemSpan",
                                      attrs: { title: item.selectItemName },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(index + 1) +
                                          "." +
                                          _vm._s(item.selectItemName)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                    (\n                    "
                                      ),
                                      item.isMandatory == "Y"
                                        ? [_vm._v(" 必填、 ")]
                                        : [_vm._v(" 选填、 ")],
                                      item.selectType == 1
                                        ? [_vm._v(" 单选 ")]
                                        : [_vm._v(" 多选 ")],
                                      _vm._v(
                                        "\n                    )\n                  "
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._l(
                                  item.selectSonItemList,
                                  function (itemSon) {
                                    return _c(
                                      "li",
                                      {
                                        key:
                                          item.sortField +
                                          "-" +
                                          itemSon.sortField,
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "checkItemSpan",
                                            staticStyle: {
                                              "text-indent": "2em",
                                            },
                                            attrs: { title: itemSon.describe },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t" +
                                                _vm._s(itemSon.value) +
                                                "." +
                                                _vm._s(itemSon.describe) +
                                                "\n\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm.inspectionTemplate.form.inspectionScoreItemList.length > 0
                ? [
                    _c("div", [
                      _c("p", { staticClass: "checkItem" }, [
                        _vm._v(_vm._s(_vm.$t("inspection.template.scoreItem"))),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "checkUl" },
                        _vm._l(
                          _vm.inspectionTemplate.form.inspectionScoreItemList,
                          function (item) {
                            return _c("li", { key: item.sortField }, [
                              _c(
                                "span",
                                {
                                  staticClass: "checkItemSpan",
                                  attrs: { title: item.scoreItemName },
                                },
                                [_vm._v(_vm._s(item.scoreItemName))]
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm.inspectionTemplate.form.inspectionCopyItemList.length > 0
                ? [
                    _c("div", [
                      _c("p", { staticClass: "checkItem" }, [
                        _vm._v(_vm._s(_vm.$t("inspection.template.copyItem"))),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "checkUl" },
                        _vm._l(
                          _vm.inspectionTemplate.form.inspectionCopyItemList,
                          function (item, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "span",
                                {
                                  staticClass: "firstSpan",
                                  attrs: { title: item.copyItemName },
                                },
                                [_vm._v(_vm._s(item.copyItemName))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "secondSpan",
                                  attrs: { title: item.copyItemUnitName },
                                },
                                [_vm._v(_vm._s(item.copyItemUnitName))]
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }